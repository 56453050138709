import React from 'react';
import PropTypes from 'prop-types';
import ClearFiltersIcon from '@material-ui/icons/Clear';
import styled from '@emotion/styled';
import {
  Typography,
  Button,
  Tooltip,
  Badge,
  IconButton,
} from '@material-ui/core';
import MessageIcon from '@material-ui/icons/Message';
import { observer } from 'mobx-react-lite';

import useBreakpoints from 'src/utils/useBreakpoints';
import { useStore } from 'src/context/StoreContext';
import {
  VACANCY_STATUS,
  CLEAN_STATUS,
  DO_NOT_DISTURB_STATUS,
} from 'src/models/RoomStore';
import RoomFilterStatusButton from './RoomFilterStatusButton';
import { useRoomFilters } from './RoomFiltersContext';

export const STAYOVER_TEXT = 'Stayover';
export const VACANT_TEXT = 'Vacant';
export const CHECKOUT_TEXT = 'Checkout';
export const BLOCKED_TEXT = 'Blocked';
export const CLEAN_TEXT = 'Clean';
export const DIRTY_TEXT = 'Dirty';
export const DO_NOT_DISTURB_TEXT = 'Do Not Disturb';

export const dataTestIds = {
  ROOM_FILTER_TOTAL_ROOM_COUNT: 'ROOM_FILTER_TOTAL_ROOM_COUNT',
  ROOM_FILTER_CLEAR_FILTER_BUTTON: 'ROOM_FILTER_CLEAR_FILTER_BUTTON',
  ROOM_FILTER_VACANCY_STATUS_STAYOVER: 'ROOM_FILTER_VACANCY_STATUS_STAYOVER',
  ROOM_FILTER_VACANCY_STATUS_CHECKOUT: 'ROOM_FILTER_VACANCY_STATUS_CHECKOUT',
  ROOM_FILTER_VACANCY_STATUS_VACANT: 'ROOM_FILTER_VACANCY_STATUS_VACANT',
  ROOM_FILTER_VACANCY_STATUS_BLOCKED: 'ROOM_FILTER_VACANCY_STATUS_BLOCKED',
  ROOM_FILTER_CLEAN_STATUS_CLEAN: 'ROOM_FILTER_CLEAN_STATUS_CLEAN',
  ROOM_FILTER_CLEAN_STATUS_DIRTY: 'ROOM_FILTER_CLEAN_STATUS_DIRTY',
  ROOM_FILTER_CLEAN_STATUS_DO_NOT_DISTURB:
    'ROOM_FILTER_CLEAN_STATUS_DO_NOT_DISTURB',
  GUEST_MESSAGE_UNREAD_COUNT: 'GUEST_MESSAGE_UNREAD_COUNT',
};

const CLEAR_FILTER_BUTTON_TEXT = 'Clear Filters';
const ROOMS_TEXT = 'Rooms';

const Container = styled('div')`
  grid-area: rooms_filters;
  display: grid;
  grid-template-columns:
    minmax(100px, 150px) minmax(150px, 450px) minmax(150px, 450px) 1fr
    minmax(50px, 200px);
  grid-template-areas: 'total_room_count vacancy_status_room_count clean_status_room_count . buttons';
  height: 60px;
  color: #666666;
  align-items: center;
  justify-items: center;
  grid-gap: 20px;
`;

const FilterStatusContainer = styled('div')`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const FilterStatusContentContainer = styled('div')`
  display: flex;
  align-items: center;
  overflow-x: scroll;
  overflow-y: hidden;
`;

const RoomFilters = observer(({ setIsDrawerOpen }) => {
  const { textMessageStore, roomStore } = useStore();
  const { isMediumOrSmallerScreen } = useBreakpoints();
  const { clearFilters, filters } = useRoomFilters();

  const isAnyFilterOn =
    Object.values(filters).filter((filterOn) => filterOn).length > 0;

  return (
    <Container>
      <Typography
        variant="h5"
        style={{
          fontWeight: 'bold',
        }}
        data-testid={dataTestIds.ROOM_FILTER_TOTAL_ROOM_COUNT}
      >
        {roomStore.roomCount} {ROOMS_TEXT}
      </Typography>

      <FilterStatusContainer>
        <Typography
          variant="caption"
          style={{
            fontWeight: 'bold',
          }}
        >
          Vacancy Status
        </Typography>
        <FilterStatusContentContainer>
          <RoomFilterStatusButton
            count={roomStore.stayoverRoomCount}
            title={STAYOVER_TEXT}
            filterType={VACANCY_STATUS.STAYOVER}
            data-testid={dataTestIds.ROOM_FILTER_VACANCY_STATUS_STAYOVER}
          />
          <RoomFilterStatusButton
            count={roomStore.vacantRoomCount}
            title={VACANT_TEXT}
            filterType={VACANCY_STATUS.VACANT}
            data-testid={dataTestIds.ROOM_FILTER_VACANCY_STATUS_VACANT}
          />
          <RoomFilterStatusButton
            count={roomStore.checkoutRoomCount}
            title={CHECKOUT_TEXT}
            filterType={VACANCY_STATUS.CHECKOUT}
            data-testid={dataTestIds.ROOM_FILTER_VACANCY_STATUS_CHECKOUT}
          />
          <RoomFilterStatusButton
            count={roomStore.blockedRoomCount}
            title={BLOCKED_TEXT}
            filterType={VACANCY_STATUS.BLOCKED}
            color="red"
            data-testid={dataTestIds.ROOM_FILTER_VACANCY_STATUS_BLOCKED}
          />
        </FilterStatusContentContainer>
      </FilterStatusContainer>

      <FilterStatusContainer>
        <Typography
          variant="caption"
          style={{
            fontWeight: 'bold',
          }}
        >
          Clean Status
        </Typography>
        <FilterStatusContentContainer>
          <RoomFilterStatusButton
            count={roomStore.cleanRoomCount}
            title={CLEAN_TEXT}
            filterType={CLEAN_STATUS.CLEAN}
            color="green"
            data-testid={dataTestIds.ROOM_FILTER_CLEAN_STATUS_CLEAN}
          />
          <RoomFilterStatusButton
            count={roomStore.dirtyRoomCount}
            title={DIRTY_TEXT}
            filterType={CLEAN_STATUS.DIRTY}
            color="orange"
            data-testid={dataTestIds.ROOM_FILTER_CLEAN_STATUS_DIRTY}
          />
          <RoomFilterStatusButton
            count={roomStore.doNotDisturbRoomCount}
            title={DO_NOT_DISTURB_TEXT}
            filterType={DO_NOT_DISTURB_STATUS}
            color="pink"
            style={{
              minWidth: '145px',
            }}
            data-testid={dataTestIds.ROOM_FILTER_CLEAN_STATUS_DO_NOT_DISTURB}
          />
        </FilterStatusContentContainer>
      </FilterStatusContainer>

      <div
        style={{
          gridArea: 'buttons',
          display: 'flex',
          alignItems: 'baseline',
          justifyContent: 'flex-end',
          width: '100%',
        }}
      >
        {isAnyFilterOn && (
          <Button
            color="red"
            variant="contained"
            onClick={clearFilters}
            data-testid={dataTestIds.ROOM_FILTER_CLEAR_FILTER_BUTTON}
            size="small"
          >
            <ClearFiltersIcon />
            {isMediumOrSmallerScreen ? null : CLEAR_FILTER_BUTTON_TEXT}
          </Button>
        )}

        <Tooltip title="Guest Messages">
          <Badge
            badgeContent={textMessageStore.numberOfUnreadMessages}
            invisible={textMessageStore.numberOfUnreadMessages < 1}
            data-testid={dataTestIds.GUEST_MESSAGE_UNREAD_COUNT}
          >
            <IconButton color="primary" onClick={() => setIsDrawerOpen(true)}>
              <MessageIcon />
            </IconButton>
          </Badge>
        </Tooltip>
      </div>
    </Container>
  );
});

RoomFilters.propTypes = {
  setOpen: PropTypes.func,
  setIsDrawerOpen: PropTypes.func,
};

export default RoomFilters;
