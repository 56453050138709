import React, { useState } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Button, Drawer } from '@material-ui/core';

import useBreakpoints from 'src/utils/useBreakpoints';
import { ReactComponent as MarkAsCleanIcon } from 'src/assets/icons/clean.svg';
import { ReactComponent as MarkAsDirtyIcon } from 'src/assets/icons/dirty.svg';
import { ReactComponent as BlockRoomIcon } from 'src/assets/icons/blocked.svg';
import { ReactComponent as DoNotDisturbIcon } from 'src/assets/icons/do_not_disturb.svg';
import { ReactComponent as RoomsIcon } from 'src/assets/icons/rooms.svg';
import { useStore } from 'src/context/StoreContext';
import { CLEAN_STATUS, VACANCY_STATUS, COLORS } from 'src/models/RoomStore';
import { COMPLETE_STATUS } from 'src/models/TaskStore';
import {
  MARK_AS_CLEAN,
  MARK_AS_DIRTY,
  BLOCK_ROOM,
  TURN_ON_DO_NOT_DISTURB,
  TURN_OFF_DO_NOT_DISTURB,
  CLOSE,
  MARK_AS_VACANT,
  MARK_AS_STAYOVER,
  UNBLOCK_CLEAN_ROOM,
  UNBLOCK_DIRTY_ROOM,
} from './RoomDetailStrings';
import RoomDetailsMenuItem from './RoomDetailsMenuItem';

const Container = styled('div')`
  display: grid;
  grid-template-rows: auto 100px;
  height: 100%;
`;

const MobileContainer = styled('div')`
  height: 50vh;
  max-height: 250px;
  padding: 15px;
  background-color: #ffffff;
  display: grid;
  grid-template-rows: repeat(autofit, auto);
  overflow-x: hidden;
  overflow-y: scroll;
`;

const MobileMenu = styled('div')`
  display: flex;
  justify-content: space-between;
  padding: 15px 25px;
  width: 100%;
  height: 100%;
  border-top: 2px solid rgba(0, 0, 0, 0.2);
`;

const CloseButtonContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CloseButton = styled(Button)`
  width: 60%;
  height: 37px;
`;

const RoomDetailsMenu = ({
  room,
  onClose,
  setCreateTaskOpen,
  setIsRoomBlock,
  isRoomBlock,
}) => {
  const { roomStore, taskStore } = useStore();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { isSmallScreen } = useBreakpoints();

  const CleanStatusMenuItem = () => {
    if (room.vacancyStatus === VACANCY_STATUS.BLOCKED) {
      return (
        <RoomDetailsMenuItem
          title={UNBLOCK_CLEAN_ROOM}
          Icon={MarkAsCleanIcon}
          onClick={() => {
            setIsDrawerOpen(false);
            if (room.incompleteTasks.length > 0) {
              // eslint-disable-next-line
              const response = window.confirm(
                'Do you want to complete the task?'
              );
              const errorNotificationText =
                'Unable to complete the task for the room. Please try again.';
              if (response) {
                room.incompleteTasks.forEach((task) => {
                  const taskUpdates = {
                    status: COMPLETE_STATUS,
                  };
                  taskStore.patchTask({
                    taskId: task.id,
                    options: { body: { task: taskUpdates } },
                    errorNotificationText,
                  });
                });
              }
            }
            roomStore.updateRoom({
              roomId: room.id,
              options: {
                body: {
                  cleanStatus: CLEAN_STATUS.CLEAN,
                  vacancyStatus: VACANCY_STATUS.VACANT,
                },
                isUpdatingFirstMenuItem: true,
              },
              errorNotificationText: `Unable to update room ${room.number} to ${CLEAN_STATUS.CLEAN}. Please try again.`,
            });
          }}
        />
      );
    }
    if (room.cleanStatus === CLEAN_STATUS.CLEAN) {
      return (
        <RoomDetailsMenuItem
          title={MARK_AS_DIRTY}
          Icon={MarkAsDirtyIcon}
          onClick={() => {
            setIsDrawerOpen(false);
            roomStore.updateRoom({
              roomId: room.id,
              options: {
                body: { cleanStatus: CLEAN_STATUS.DIRTY },
                isUpdatingFirstMenuItem: true,
              },
              errorNotificationText: `Unable to update room ${room.number} to ${CLEAN_STATUS.DIRTY}. Please try again.`,
            });
          }}
        />
      );
    }
    return (
      <RoomDetailsMenuItem
        title={MARK_AS_CLEAN}
        Icon={MarkAsCleanIcon}
        onClick={() => {
          setIsDrawerOpen(false);
          roomStore.updateRoom({
            roomId: room.id,
            options: {
              body: { cleanStatus: CLEAN_STATUS.CLEAN },
              isUpdatingFirstMenuItem: true,
            },
            errorNotificationText: `Unable to update room ${room.number} to ${CLEAN_STATUS.CLEAN}. Please try again.`,
          });
        }}
      />
    );
  };

  const BlockMenuItem = () => {
    if (room.vacancyStatus === VACANCY_STATUS.BLOCKED) {
      return (
        <RoomDetailsMenuItem
          title={UNBLOCK_DIRTY_ROOM}
          Icon={MarkAsDirtyIcon}
          onClick={() => {
            setIsDrawerOpen(false);
            if (room.incompleteTasks.length > 0) {
              // eslint-disable-next-line
              const response = window.confirm(
                'Do you want to complete the task?'
              );
              const errorNotificationText =
                'Unable to complete the task for the room. Please try again.';
              if (response) {
                room.incompleteTasks.forEach((task) => {
                  const taskUpdates = {
                    status: COMPLETE_STATUS,
                  };
                  taskStore.patchTask({
                    taskId: task.id,
                    options: { body: { task: taskUpdates } },
                    errorNotificationText,
                  });
                });
              }
            }
            roomStore.updateRoom({
              roomId: room.id,
              options: {
                body: {
                  cleanStatus: CLEAN_STATUS.DIRTY,
                  vacancyStatus: VACANCY_STATUS.VACANT,
                },
                isUpdatingSecondMenuItem: true,
              },
              errorNotificationText: `Unable to update room ${room.number} to ${CLEAN_STATUS.DIRTY}. Please try again.`,
            });
          }}
        />
      );
    }
    return (
      <RoomDetailsMenuItem
        title={BLOCK_ROOM}
        Icon={BlockRoomIcon}
        onClick={() => {
          setIsDrawerOpen(false);
          if (room.incompleteTasks.length === 0) {
            setCreateTaskOpen(true);
            setIsRoomBlock(true);
          } else {
            roomStore.updateRoom({
              roomId: room.id,
              options: {
                body: { vacancyStatus: VACANCY_STATUS.BLOCKED },
                isUpdatingSecondMenuItem: true,
              },
              errorNotificationText: `Unable to update room ${room.number} to ${VACANCY_STATUS.BLOCKED}. Please try again.`,
            });
          }
        }}
        disabled={isRoomBlock}
      />
    );
  };

  const DoNotDisturbMenuItem = () => {
    if (room.doNotDisturb) {
      return (
        <RoomDetailsMenuItem
          title={TURN_OFF_DO_NOT_DISTURB}
          Icon={DoNotDisturbIcon}
          onClick={() => {
            setIsDrawerOpen(false);
            roomStore.updateRoom({
              roomId: room.id,
              options: {
                body: { doNotDisturb: false },
                isUpdatingThirdMenuItem: true,
              },
              errorNotificationText: `Unable to turn off Do not Disturb for room ${room.number}. Please try again.`,
            });
          }}
          disabled={room.vacancyStatus !== VACANCY_STATUS.STAYOVER}
          selectedColor={COLORS.DO_NOT_DISTURB}
        />
      );
    }

    return (
      <RoomDetailsMenuItem
        title={TURN_ON_DO_NOT_DISTURB}
        Icon={DoNotDisturbIcon}
        onClick={() => {
          setIsDrawerOpen(false);
          roomStore.updateRoom({
            roomId: room.id,
            options: {
              body: { doNotDisturb: true },
              isUpdatingThirdMenuItem: true,
            },
            errorNotificationText: `Unable to turn on Do not Disturb for room ${room.number}. Please try again.`,
          });
        }}
        disabled={room.vacancyStatus !== VACANCY_STATUS.STAYOVER}
      />
    );
  };

  const StayoverMenuItem = () => {
    if (room.vacancyStatus === VACANCY_STATUS.VACANT) {
      return (
        <RoomDetailsMenuItem
          title={MARK_AS_STAYOVER}
          Icon={RoomsIcon}
          onClick={() => {
            setIsDrawerOpen(false);
            roomStore.updateRoom({
              roomId: room.id,
              options: {
                body: { vacancyStatus: VACANCY_STATUS.STAYOVER },
                isUpdatingFourthMenuItem: true,
              },
              errorNotificationText: `Unable to update room ${room.number} to ${VACANCY_STATUS.STAYOVER}. Please try again.`,
            });
          }}
        />
      );
    }
    return (
      <RoomDetailsMenuItem
        title={MARK_AS_VACANT}
        Icon={RoomsIcon}
        onClick={() => {
          setIsDrawerOpen(false);
          roomStore.updateRoom({
            roomId: room.id,
            options: {
              body: { vacancyStatus: VACANCY_STATUS.VACANT },
              isUpdatingFourthMenuItem: true,
            },
            errorNotificationText: `Unable to update room ${room.number} to ${VACANCY_STATUS.VACANT}. Please try again.`,
          });
        }}
      />
    );
  };

  return isSmallScreen ? (
    <>
      <MobileMenu>
        <Button color="grey" onClick={onClose}>
          Back
        </Button>

        <Button variant="contained" onClick={() => setIsDrawerOpen(true)}>
          Room Actions
        </Button>
      </MobileMenu>
      <Drawer
        style={{
          zIndex: 9999,
        }}
        anchor="bottom"
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        disableSwipeToOpen={false}
      >
        <MobileContainer>
          <CleanStatusMenuItem />
          <BlockMenuItem />
          <DoNotDisturbMenuItem />
          <StayoverMenuItem />
        </MobileContainer>
      </Drawer>
    </>
  ) : (
    <Container>
      <div>
        <CleanStatusMenuItem />
        <BlockMenuItem />
        <DoNotDisturbMenuItem />
        <StayoverMenuItem />
      </div>

      <CloseButtonContainer>
        <CloseButton variant="outlined" onClick={onClose}>
          {CLOSE}
        </CloseButton>
      </CloseButtonContainer>
    </Container>
  );
};

RoomDetailsMenu.propTypes = {
  room: PropTypes.shape({
    id: PropTypes.number,
    number: PropTypes.string,
    cleanStatus: PropTypes.oneOf(
      Object.keys(CLEAN_STATUS).map((key) => CLEAN_STATUS[key])
    ),
    vacancyStatus: PropTypes.oneOf(
      Object.keys(VACANCY_STATUS).map((key) => VACANCY_STATUS[key])
    ),
    doNotDisturb: PropTypes.bool,
    incompleteTasks: PropTypes.array,
  }).isRequired,
  onClose: PropTypes.func,
  isRoomBlock: PropTypes.bool,
  setIsRoomBlock: PropTypes.func,
  setCreateTaskOpen: PropTypes.func,
};

export default RoomDetailsMenu;
