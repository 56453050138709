import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Typography } from '@material-ui/core';

import { ReactComponent as RightArrowIcon } from 'src/assets/icons/right_arrow.svg';
import CountCircle from 'src/components/CountCircle/CountCircle';

export const UNREAD_MESSAGE_COUNT_GUEST = 'UNREAD_MESSAGE_COUNT_GUEST_';

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  max-height: 100px;
  padding: 15px;
  padding-bottom: 25px;
  position: relative;
  border-bottom: solid 1px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  width: 100%;
`;

const HeaderContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: flex-center;
  margin-bottom: 15px;
  width: 100%;
`;

const RightArrow = styled(RightArrowIcon)`
  height: 12px;
  width: 12px;
  fill: #5b5b5b;
  margin-bottom: 5px;
  margin-right: 5px;
`;

const AGO_TEXT = 'ago';

const GuestMessagePreview = ({
  textMessage,
  onClick,
  numberOfUnreadMessages,
  rooms,
}) => {
  const truncate = (str, n) => {
    if (str.length <= n) {
      return str;
    }
    const subString = str.substr(0, n - 1);
    return `${subString}...`;
  };
  const { roomId } = textMessage.guest;
  const roomNumber = rooms.get(roomId).number;

  return (
    <Container onClick={onClick}>
      <HeaderContainer>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography
            style={{ marginRight: '7px', color: '#5B5B5B', fontWeight: 'bold' }}
            variant="subtitle1"
          >
            {`Room ${roomNumber}`}
          </Typography>
          <CountCircle
            data-testid={`${UNREAD_MESSAGE_COUNT_GUEST}${textMessage.guestId}`}
            count={numberOfUnreadMessages}
            isVisible={numberOfUnreadMessages && numberOfUnreadMessages > 0}
          />
        </div>
        <Typography style={{ color: '#5B5B5B' }} variant="body2">
          {`${moment
            .duration(moment() - moment(textMessage.sentAt))
            .humanize()} ${AGO_TEXT}`}
        </Typography>
      </HeaderContainer>
      <div
        style={{
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Typography style={{ color: '#5B5B5B', width: '85%' }} variant="body1">
          {truncate(textMessage.content, 65)}
        </Typography>
        <RightArrow />
      </div>
    </Container>
  );
};

GuestMessagePreview.propTypes = {
  textMessage: PropTypes.shape({
    id: PropTypes.number.isRequired,
    sentAt: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    guestId: PropTypes.number.isRequired,
    guest: PropTypes.shape({
      roomId: PropTypes.number,
    }),
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  numberOfUnreadMessages: PropTypes.number,
  rooms: PropTypes.object,
};

export default GuestMessagePreview;
