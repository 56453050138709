import React from 'react';
import styled from '@emotion/styled';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { ReactComponent as CloseIcon } from 'src/assets/icons/close.svg';

import { useStore } from 'src/context/StoreContext';
import CountCircle from 'src/components/CountCircle/CountCircle';

export const EXIT_GUEST_MESSAGES = 'EXIT_GUEST_MESSAGES';
export const HEADER_TOTAL_UNREAD_MESSAGE_COUNT =
  'HEADER_TOTAL_UNREAD_MESSAGE_COUNT';

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f9f9f9;
  min-height: 75px;
  position: relative;
  border-bottom: solid 1px #ebebeb;
  width: 100%;
`;

const Close = styled(CloseIcon)`
  fill: #5b5b5b;
  cursor: pointer;
  position: absolute;
  left: 15px;
  width: 13px;
  height: 13px;
`;

const HEADER = 'Guest Messages';
const SUBHEADER = 'UNREAD';

const GuestMessageScreenHeader = observer(
  ({ setIsDrawerOpen, setSelectedGuestId }) => {
    const { textMessageStore } = useStore();

    return (
      <Container>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {setIsDrawerOpen && (
            <Close
              data-testid={EXIT_GUEST_MESSAGES}
              onClick={() => {
                setIsDrawerOpen(false);
                setSelectedGuestId(null);
              }}
            />
          )}
          <Typography variant="h6" style={{ color: '#5B5B5B' }}>
            {HEADER}
          </Typography>
        </div>

        {textMessageStore.numberOfUnreadMessages > 0 ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <CountCircle
              data-testid={HEADER_TOTAL_UNREAD_MESSAGE_COUNT}
              count={textMessageStore.numberOfUnreadMessages}
            />

            <Typography
              style={{ marginLeft: '8px', color: '#5B5B5B' }}
              variant="body2"
            >
              {SUBHEADER}
            </Typography>
          </div>
        ) : null}
      </Container>
    );
  }
);

GuestMessageScreenHeader.propTypes = {
  setIsDrawerOpen: PropTypes.func,
};

export default GuestMessageScreenHeader;
