import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Typography } from '@material-ui/core';

import { ReactComponent as TaskImage } from 'src/assets/icons/task.svg';
import { useStore } from 'src/context/StoreContext';

const TaskIcon = styled(TaskImage)`
  width: 34px;
  margin-right: 16px;
`;

const HeaderContainer = styled('div')`
  display: flex;
  flex-direction: column;
`;

const Header = styled(Typography)`
  margin: 1px;
`;

const SubHeader = styled(Typography)`
  margin: 1px;
`;

const TaskDetailsCardHeader = observer(({ task }) => {
  const { userStore } = useStore();
  const { authenticatedUser } = userStore;
  const { languagePreference } = authenticatedUser;
  let assignedName;
  if (task.taskAssignments.length > 0) {
    const assignedId = task.taskAssignments[0].userId;
    assignedName = userStore.users.get(assignedId).username;
  }

  return (
    <>
      <TaskIcon />
      <HeaderContainer>
        <Header variant="h6">{task.description[languagePreference]}</Header>
        <SubHeader variant="subtitle2">
          {assignedName ? `Assigned to ${assignedName}` : 'Unassigned'}
        </SubHeader>
      </HeaderContainer>
    </>
  );
});

TaskDetailsCardHeader.propTypes = {
  task: PropTypes.object,
};

export default TaskDetailsCardHeader;
