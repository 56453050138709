import React from 'react';
import styled from '@emotion/styled';
import CloseIcon from '@material-ui/icons/Close';
import { Modal } from '@material-ui/core';
import PropTypes from 'prop-types';

import CreateEditTaskScreen from './CreateEditTaskScreen';

const Container = styled('div')`
  outline: 0;
  width: 100%;
  display: grid;
  grid-template-rows: 75px 1fr;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    height: 100%;
  }
`;

const Header = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  border-bottom: 2px solid #20b2aa;
  font-weight: bold;
  padding: 5px 30px;
`;

const MainContent = styled('div')`
  padding-bottom: 20px;
  background-color: #e9e9e9;
`;

const StyledModal = styled(Modal)`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin: auto;

  @media (min-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    width: 70%;
    height: 900px;
  }
`;

const CREATE_TASK_HEADER_TITLE = 'Create a Task';

const CreateTaskModal = ({ setIsModalOpen, isModalOpen }) => {
  return (
    <StyledModal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
      <Container>
        <Header>
          <p>{CREATE_TASK_HEADER_TITLE}</p>
          <CloseIcon
            onClick={() => setIsModalOpen(false)}
            style={{
              width: '20px',
              height: '20px',
              fill: '#737373',
              cursor: 'pointer',
            }}
          />
        </Header>
        <MainContent>
          <CreateEditTaskScreen handleGoBack={() => setIsModalOpen(false)} />
        </MainContent>
      </Container>
    </StyledModal>
  );
};

CreateTaskModal.propTypes = {
  isModalOpen: PropTypes.bool,
  setIsModalOpen: PropTypes.func,
};

export default CreateTaskModal;
