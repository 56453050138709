import React from 'react';
import {
  BottomNavigation,
  BottomNavigationAction,
  Badge,
} from '@material-ui/core';
import { Link, useRouteMatch } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { useStore } from 'src/context/StoreContext';
import RoomsIcon from '@material-ui/icons/GridOn';
import MessagesIcon from '@material-ui/icons/Message';
import { ReactComponent as TasksIcon } from 'src/assets/icons/task.svg';
import { DASHBOARD_PATH } from 'src/LBRouterSwitch';

const BottomNavigationBar = observer(() => {
  const { textMessageStore } = useStore();

  const routeMatch = useRouteMatch([DASHBOARD_PATH, '/tasks', '/messages']);
  const currentRoute = routeMatch?.path;

  return (
    <BottomNavigation
      showLabels
      value={currentRoute}
      style={{
        gridArea: 'navbar',
      }}
    >
      <BottomNavigationAction
        label="Rooms"
        value={DASHBOARD_PATH}
        to={DASHBOARD_PATH}
        icon={<RoomsIcon />}
        component={Link}
      />
      <BottomNavigationAction
        label="Tasks"
        value="/tasks"
        to="/tasks"
        icon={<TasksIcon />}
        component={Link}
      />
      <BottomNavigationAction
        label="Messages"
        value="/messages"
        to="/messages"
        icon={
          <Badge
            badgeContent={textMessageStore.numberOfUnreadMessages}
            invisible={textMessageStore.numberOfUnreadMessages < 1}
          >
            <MessagesIcon />{' '}
          </Badge>
        }
        component={Link}
      />
    </BottomNavigation>
  );
});

export default BottomNavigationBar;
